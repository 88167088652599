import { motion } from 'framer-motion';
import React from 'react';
import ReactTooltip from 'react-tooltip';
import { data } from '../../contants';
import { AppWrap, MotionWrap } from '../../wrapper';
import './Skills.css';
function Skills() {
  return (
    <>
      <h2 className='head-text'>Skills & Experience</h2>
      <div className='app__skills-container'>
        <motion.div className='app__skills-list'>
          {data.skills.map((skill, index) => {
            return (
              <motion.div
                whileInView={{ opacity: [0, 1] }}
                transition={{ duration: 0.5 }}
                className='app__skills-item app__flex'
                key={`${skill.name}__${index}`}>
                <div className='app__flex' style={{ backgroundColor: skill.bgColor }}>
                  <img src={skill.icon} alt={skill.name} />
                </div>
                <p className='p-text'>{skill.name}</p>
              </motion.div>
            )
          })}
        </motion.div>
        <motion.div className='app__skills-exp'>
          {
            data.experiences.map((exp) => {
              return (<motion.div
                className='app__skills-exp-item'
                key={exp.year}>
                <div className='app__skills-exp-year' key={`exp__year_div__${exp.year}`}>
                  <p className='bold-text'>{exp.year}</p>
                </div>
                <motion.div className='app__skills-exp-works' key={`works__${exp.year}`}>
                  {exp.works.map((work, index) => {
                    return (
                      <>
                        <motion.div
                          whileInView={{ opacity: [0, 1] }}
                          transition={{ duration: 0.5 }}
                          className='app__skills-exp-work'
                          data-tip
                          data-for={`tooltip_${exp.year}_${index}`}
                          key={`work__${work.title}`}
                        >
                          <h4 className='bold-text'>{work.title}</h4>
                          {work.companyUrl && <p className='p-text' ><a className='link-url' href={work.companyUrl} target='_blank' rel='noreferrer' >{work.company}</a></p>}
                        </motion.div>
                        <ReactTooltip
                          id={`tooltip_${exp.year}_${index}`}
                          effect='solid'
                          arrowColor='#fff'
                          className='skills-tooltip' key={`tooltip__${index}`}>
                          {work.description}
                        </ReactTooltip>
                      </>)
                  })}
                </motion.div>
              </motion.div>)
            })
          }
        </motion.div>
      </div>
    </>
  )
}



export default AppWrap(
  MotionWrap(Skills, 'app__skills'),
  'skills', 'app__whitebg');