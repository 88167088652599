import React from 'react';
import { FaGithub, FaLinkedinIn, FaCode } from 'react-icons/fa';


const SocialMedia = () => {
    const navigateTo = (url) => {
        window.open(url, '_blank').focus();
    }
    return (
        <div className='app__social'>
            <div>
                <FaCode onClick={() => navigateTo('https://leetcode.com/yhamdane/')} />
            </div>
            <div>
                <FaGithub onClick={() => navigateTo('https://github.com/HyounesH')} />
            </div>
            <div>
                <FaLinkedinIn onClick={() => navigateTo('https://www.linkedin.com/in/younes-hamdane/')} />
            </div>
        </div>
    )
}

export default SocialMedia;