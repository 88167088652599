import emailjs from '@emailjs/browser';
import React, { useRef, useState } from 'react';
import { images } from '../../contants';
import { AppWrap, MotionWrap } from '../../wrapper';
import './Footer.css';

function Footer() {
  const form = useRef();
  const [formData, setFormData] = useState({ name: '', email: '', message: '' });
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const { name, email, message } = formData

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value })
  }
  const handleSubmit = () => {
    setLoading(true);
    emailjs.sendForm('service_klo7thv', 'template_72nhgea', form.current, 'h5WZsczniRyv866fH')
      .then((result) => {
        setIsFormSubmitted(true);
      }, (error) => {
        console.error(error.text);
      });
  }



  return (
    <>
      <h2 className='head-text'>Take a coffe & chat with me</h2>
      <div className='app__footer-cards'>
        <div className='app__footer-card'>
          <img src={images.email} alt='email' />
          <a href='mailto:hello@yhamdane.com'>hello@yhamdane.com</a>
        </div>
        <div className='app__footer-card'>
          <img src={images.mobile} alt='email' />
          <a href='tel:+33765747753'>+33 7 65 74 77 53</a>
        </div>
      </div>
      {!isFormSubmitted ?

        <div className='app__footer-form app__flex'>
          <form ref={form}>
            <div className='app__flex'>
              <input className='p-text' placeholder='Your Name' name='name' value={name} onChange={handleChangeInput} />
            </div>
            <div className='app__flex'>
              <input className='p-text' placeholder='Your Email' name='email' value={email} onChange={handleChangeInput} />
            </div>
            <div>
              <textarea className='p-text' placeholder='Your message' value={message} name='message' onChange={handleChangeInput} />
            </div>
            <button type='button' className='p-text' onClick={handleSubmit}>{loading ? 'Sending' : 'Send Message'}</button>
          </form>
        </div>
        :
        <div>
          <h3 className='head-text'>Thank you for getting in touch !</h3>
        </div>
      }
    </>
  )
}

export default AppWrap(
  MotionWrap(Footer, 'app__footer'),
  'contact', 'app__whitebg');