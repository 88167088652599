import React from 'react';
import { motion } from 'framer-motion';
import { images } from '../../contants';
import { AppWrap } from '../../wrapper';
import './Header.css';
const scaleVariants = {
  whileInView: {
    scale: [0, 1],
    oppacity: [0, 1],
    transition: {
      duration: 1,
      ease: 'easeInOut'
    }
  }
}
function Header() {
  return (
    <div className='app__header app__flex'>
      <motion.div
        whileInView={{ x: [-100, 0], oppacity: [0, 1] }}
        transition={{ duration: 0.5 }}
        className="app__header-info">
        <div className='app__header-badge'>
          <div className='badge-cmp  app__flex'>
            <span>👋</span>
            <div style={{ marginLeft: 20 }}>
              <p className='p-text'> Hello, I am</p>
              <h1 className='head-text'>Younes</h1>
            </div>
          </div>
          <div className='tag-cmp app__flex'>
            <p className='p-text'>Full-stack Software Engineer</p>
            <p className='p-text'>Freelancer</p>
          </div>
        </div>
      </motion.div>
      <motion.div
        whileInView={{ oppacity: [0, 1] }}
        transition={{ duration: 0.5, delayChildren: 0.5 }}
        className="app__header-img">
        <img src={images.profile} alt='profile' />
        <motion.img
          whileInView={{ scale: [0, 1] }}
          transition={{ duration: 0.5, ease: 'easeInOut' }}
          src={images.circle}
          alt='profile cirle'
          className="overlay-cirle"
        >
        </motion.img>
      </motion.div>
      <motion.div
        variants={scaleVariants}
        whileInView={scaleVariants.whileInView}
        className='app__header-circles'>
        {[images.springboot,images.java, images.kafka, images.angular, images.react].map((circle, index) => {
          return <div className='circle-cmp app__flex' key={`circle_${index}`}>
            <img src={circle} alt='tech language and framework' />
          </div>
        })}
      </motion.div>
    </div>
  )
}

export default AppWrap(Header, 'portfolio');