import React from 'react'
import { SocialMedia, NavigationDots } from '../components';
const year = (new Date()).getFullYear();
const AppWrap = (Component, idName, classNames) => function HOC() {
    return (
        <div id={idName} className={`app__container ${classNames}`}>
            <SocialMedia />
            <div className='app__wrapper app__flex'>
                <Component />
                <div className='copyright'>
                    <p className='p-text'>@{year} YHAMDANE</p>
                    <p className='p-text'>ALL rights reserved</p>
                </div>
            </div>
            <NavigationDots active={idName}/>
        </div>
    )
}

export default AppWrap