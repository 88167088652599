import React from 'react';
import { motion } from 'framer-motion';
import './About.css';
import { data } from '../../contants';
import { AppWrap, MotionWrap } from '../../wrapper';
function About() {
  return (
    <>
      <h2 className='head-text'> I know that <span>Good Apps</span><br /> means <span>Good business</span></h2>
      <div className='app__profiles'>
        {data.abouts.map((about, index) => {
          return <motion.div
            whileInView={{ opacity: 1 }}
            whileHover={{ scale: 1.1 }}
            transition={{ duration: 0.5, type: 'tween' }}
            className='app__profile-item'
            key={about.title}>
            <img src={about.imgUrl} alt={about.title} key={`item_${index}`} />
            <h2 className='bold-text' style={{ marginTop: 20 }} key={`title_${index}`}>{about.title}</h2>
            <p className='p-text' style={{ marginTop: 10 }} key={`desc_${index}`}>{about.description}</p>
          </motion.div>
        })}
      </div>
    </>
  )
}

export default AppWrap(
  MotionWrap(About, 'app__about'),
  'about', 'app__whitebg');