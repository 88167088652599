import images from "./images";
const abouts = [
    { title: 'Backend Software Engineer', description: 'I am an experienced Backend software engineer', imgUrl: images.about04 },
    { title: 'Frontend Software Engineer', description: 'I am a javascript / Typescript lover and experienced frontend software engineer', imgUrl: images.about01 },
    { title: 'Problem solving lover', description: 'I am a problem solving lover', imgUrl: images.about03 },
    { title: 'Big fan of microservice architecture', description: 'I am a big fan of microservice architecture', imgUrl: images.about02 },
];
const workCategories = ['Backend', 'Frontend', 'Kubernetes', 'Cloud', 'Jenkins', 'GIT', 'Spring boot', 'Java', 'React', 'Angular', 'All']
const works = [
    {
        title: 'Full-Stack software Engineer Java',
        company: 'Chanel',
        companyLogo: images.chanel,
        companyUrl: 'https://www.chanel.com/',
        description: 'Developped backoffice to manage chanel products such as watches, jewerly ',
        tags: ['Java', 'Spring', 'SAP Hybris', 'Backend', 'Frontend', 'Groovy', 'XML', 'Cloud', 'Kubernetes', 'Jenkins', 'GIT']
    },
    {
        title: 'Full-Stack software Engineer Java/Angular',
        company: 'SGS',
        companyLogo: images.sgs,
        companyUrl: 'https://www.sgs.com/en',
        description: 'Developed and redesigned the platform Tradeworks, which is a web application for SGS offices around the world to inspect imported and exported products.',
        duration: '',
        tags: ['Java', 'Spring boot', 'Angular', 'Typescript', 'Backend', 'Frontend', 'Kubernetes', 'Cloud', 'Jenkins', 'GIT']
    },
    {
        title: 'Full-Stack software Engineer Java/React',
        company: 'Adria Business & technology',
        companyLogo: images.adria,
        companyUrl: 'https://adria-bt.com/en/',
        description: 'Migrate web application into microservice architecture for Attijariwafa Bank of egypt',
        duration: '',
        tags: ['Java', 'Spring boot', 'React', 'Javascript', 'Backend', 'Frontend', 'Cloud', 'Jenkins', 'GIT']
    }
];

const skills = [
    { name: 'JAVA', icon: images.java, bgColor: '#eff0f5d9' },
    { name: 'SPRING BOOT', icon: images.springboot, bgColor: '#eff0f5d9' },
    { name: 'REACT', icon: images.react, bgColor: '#eff0f5d9' },
    { name: 'Typescript', icon: images.typescript, bgColor: '#eff0f5d9' },
    { name: 'Javascript', icon: images.javascript, bgColor: '#eff0f5d9' },
    { name: 'Redux', icon: images.redux, bgColor: '#eff0f5d9' },
    { name: 'SASS', icon: images.sass, bgColor: '#eff0f5d9' },
    { name: 'Angular', icon: images.angular, bgColor: '#eff0f5d9' },
    { name: 'Jenkins', icon: images.jenkins, bgColor: '#eff0f5d9' },
    { name: 'Kubernetes', icon: images.Kubernetes, bgColor: '#eff0f5d9' },
    { name: 'Docker', icon: images.docker, bgColor: '#eff0f5d9' },
    { name: 'Kafka', icon: images.kafka, bgColor: '#eff0f5d9' },
    { name: 'Node', icon: images.node, bgColor: '#eff0f5d9' },
    { name: 'CSS', icon: images.css, bgColor: '#eff0f5d9' },
    { name: 'GIT', icon: images.git, bgColor: '#eff0f5d9' },
]

const experiences = [
     {
        year: '04/2022 - NOW',
        works: [
            {
                title: 'Full-Stack Software Engineer Java/Angular/ReactJs (Freelance)',
                description: 'Full-Stack Software Engineer Java, Angular, ReactJs And next.js',
                tags: ['Java', 'Spring boot', 'Angular', 'ReactJs','Next.js','Cloud','Docker','MongoDB','Typescript', 'Backend', 'Frontend']

            }
        ]
     },
    {
        year: '10/2019 - 01/2023',
        works: [
            {
                title: 'Full-Stack software Engineer Java',
                company: 'Chanel',
                companyLogo: images.chanel,
                companyUrl: 'https://www.chanel.com/',
                description: 'Developed the Backoffice PIM WFJ for management and enrichment of the Chanel data products related to fashion, watches, and jewelry.',
                tags: ['Java', 'Spring', 'SAP Hybris', 'Backend', 'Frontend', 'Groovy', 'XML']
            },
            {
                title: 'Full-Stack software Engineer Java/Angular',
                company: 'SGS',
                companyLogo: images.sgs,
                companyUrl: 'https://www.sgs.com/en',
                description: "Developed and redesigned the platform Tradeworks, which is a web application for SGS offices around the world to inspect imported and exported products and provide a certiﬁcate that respects both international standards depending on the items and the country's restrictions for SGS client.",
                duration: '',
                tags: ['Java', 'Spring boot', 'Angular', 'Typescript', 'Backend', 'Frontend']
            }
        ]
    },
    {
        year: '08/2019 - 09/2019',
        works: [
            {
                title: 'Full-Stack software Engineer Java/React',
                company: 'Adria Business & technology',
                companyUrl: 'https://adria-bt.com/en/',
                description: "Developed a micro-service that collects and extracts the data coming from different resources and loaded to be consumed by other services.",
            }
        ]
    }
]

const data = {
    abouts,
    works,
    workCategories,
    skills,
    experiences
};


export default data;