import React, { useState } from 'react'
import './Navbar.css';
import { images } from '../../contants';
import { HiMenuAlt4, HiX } from 'react-icons/hi';
import { motion } from 'framer-motion';
function Navbar() {
    const [toggle, setToggle] = useState(false);
    const onLogoClick = () => {
        window.location.href = window.location.origin;
    }
    return (
        <nav className='app__navbar'>
            <div className='app__navbar-logo'>
                <img src={images.logo} alt="logo" onClick={onLogoClick} />
            </div>
            <ul className='app__navbar-links'>
                <li className='app_flex p-text'><a href='portfolio'>Portfolio</a>
                    <ul className='app_navbar-submenu'>
                        {['about', 'work', 'skills', 'contact'].map((item) => {
                            return (
                                <li key={`link-${item}`} className="app__flex p-text">
                                    <div />
                                    <a href={`#${item}`}>{item}</a>
                                </li>
                            )
                        })
                        }
                    </ul>
                </li>
            </ul>
            <div className='app__navbar-menu'>
                <HiMenuAlt4 onClick={() => setToggle(true)} />
                {
                    toggle && (
                        <motion.div
                            whileInView={{ x: [300, 0] }}
                            transition={{ duration: 0.85, ease: 'easeOut' }}
                        >
                            <HiX onClick={() => setToggle(false)} />

                            <ul>
                                <li>Portfolio</li>
                                <ul>
                                    {
                                        ['about', 'work', 'skills', 'contact'].map((item) => {
                                            return (
                                                <li key={item} className="app__flex p-text">
                                                    <a href={`#${item}`} onClick={() => setToggle(false)}>{item}</a>
                                                </li>
                                            )
                                        })
                                    }</ul>
                            </ul>
                        </motion.div>
                    )
                }
            </div>
        </nav>
    )
}

export default Navbar