import React, { useState } from 'react';
import { AiFillEye } from 'react-icons/ai';
import { motion } from 'framer-motion';
import { AppWrap, MotionWrap } from '../../wrapper';
import { data } from '../../contants';
import './Work.css'
function Work() {
  const [activeFilter, setActiveFilter] = useState('All');
  const [animateCard, setAnimateCard] = useState({ y: 0, opacity: 1 })
  const [filteredWorks, setFilteredWorks] = useState(data.works)
  const handleWorkFilter = (item) => {
    setActiveFilter(item);
    setAnimateCard([{ y: 100, opacity: 0 }]);
    setTimeout(() => {
      setAnimateCard([{ y: 0, opacity: 1 }]);
    }, 500);
    if (item === 'All') {
      setFilteredWorks(data.works);
    } else {
       setFilteredWorks(data.works.filter(work=> work.tags.includes(item)));
    }
  }
  return (
    <>
      <h2 className='head-text'>My work <span>Experience </span><br />section </h2>
      <div className='app__work-filter'>
        {data.workCategories.map((item, index) => {
          return (
            <div key={index}
              onClick={() => handleWorkFilter(item)}
              className={`app__work-filter-item app__flex p-text ${activeFilter === item ? 'active-item' : ''}`}
            >
              {item}
            </div>
          )
        })}
      </div>
      <motion.div
        animate={animateCard}
        transition={{ duration: 0.5, delayChildren: 0.5 }}
        className='app__work-portfolio'
      >
        {
          filteredWorks.map((work, index) => {
            return (
              <div className='app__work-item app__flex' key={index}>
                <div className='app__work-img app__flex'>
                  <img src={work.companyLogo} alt={work.company} />
                  <motion.div
                    whileHover={{ opacity: [0, 1] }}
                    transition={{ duration: 0.25, ease: 'easeInOut', staggerChildren: 0.5 }}
                    className='app__work-hover app__flex'
                  >
                    <a href={work.companyUrl} target='_blank' rel='noreferrer' >

                      <motion.div
                        whileInView={{ scale: [0, 1] }}
                        whileHover={{ scale: [1, 0.9] }}
                        transition={{ duration: 0.25 }}
                        className='app__flex'
                      >
                        <AiFillEye />
                      </motion.div>
                    </a>

                  </motion.div>
                </div>
                <div className='app__work-content app__flex'>
                  <h4 className='bold-text'>{work.title}</h4>
                  <p className='p-text' style={{ marginTop: 10 }}>{work.description}</p>
                  <div className='app__work-tag app__flex'>
                    <p className='p-text'>{work.tags.length > 0 ? work.tags[0] : ''}</p>
                  </div>
                </div>
              </div>
            )
          })
        }

      </motion.div>

    </>
  )
}


export default AppWrap(
  MotionWrap(Work, 'app__works'),
  'work', 'app__primarybg');